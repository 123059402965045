
import { useWindownSize } from "@/helpers";
import { defineComponent, onUnmounted } from "vue";

export default defineComponent({
  name: "Loading",
  props: ["loading"],
  setup() {
    const { vw, vh, remove } = useWindownSize();
    onUnmounted(() => {
      remove();
    });
    return { vw, vh };
  }
});
